body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "mobile";
  src: local("mobile"), url("../public/impact.ttf") format("truetype");
}

@keyframes logo {
  from {
    right: 100%;
    opacity: 0;
  }

  to {
    right: 0%;
    opacity: 1;
  }
}

@keyframes info {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
